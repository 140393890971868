import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map, retry } from 'rxjs/operators';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs'
// import { Toast } from '@ionic-native/toast/ngx';
//import { Toast } from '@capacitor/toast';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  link: any;
  data: any
  detail_data: any
  profile_data: any
  post_data: any
  img: any
  filter_data: any
  Ad_data: any
  edit_ad: any
  chat_status: any
  login_type: any
  image_url:any
  profileData:any
  imamProfile
  constructor(private router: Router, public http: Http,
    public loadingController: LoadingController,
    public toastController: ToastController) {
      this.link = 'https://askimams.org/api/api.php'
      this.image_url = 'https://askimams.org/api/images/'
  }
  getImageURL() {   
    return this.image_url
  }
 
  setLoginOption(data) {
    this.login_type = data
  }
  
  getLoginOption() {
    return this.login_type
  }
  login(email, password, user_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'login');
    postData.append('email', email)
    postData.append('password', password)
    postData.append('user_type', user_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  register(username,email, name, password, user_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'register');
    postData.append('username', username)
    postData.append('email', email)
    postData.append('name', name)
    postData.append('password', password)
    postData.append('user_type', user_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  register_imam(email, name, password, user_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'register');
    // postData.append('username', username)
    postData.append('email', email)
    postData.append('name', name)
    postData.append('password', password)
    postData.append('user_type', user_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  banner_img(): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    // postData.append('username', username)
    postData.append('table_name', 'slider')
  
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  getProfile() {
    return this.profileData
  }
  setProfile(data) {
    this.profileData = data
  }
  profile(user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'profile');
    postData.append('user_id', user_id)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  profile_imam(user_id,imam_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'users');
    postData.append('user_id', user_id)
    postData.append('user_type', '1')
    postData.append('id', imam_id)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  forgot_password(email): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'forgot_password');
    postData.append('email', email)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  social_login(token,name,email,picture,user_type,check):Observable<any[]>{
    let postData = new FormData();
    postData.append('type','social_login');
    postData.append('token',token)
    postData.append('name',name)
    postData.append('email',email)
    postData.append('picture',picture)
    postData.append('user_type', user_type)
    postData.append('social_type', check)
    return this.http.post(this.link,postData).pipe(map(res=>res.json()))
  }
  update_profile(user_id, name, username, email, phone, image, address, website, organization, about, qualification,youtube): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'update_profile');
    postData.append('user_id', user_id)
    postData.append('name', name)
    postData.append('username', username)
    postData.append('phone', phone)
    postData.append('email', email)
    postData.append('image', image)
    postData.append('address', address)
    postData.append('website', website)
    postData.append('organization', organization)
    postData.append('about', about)
    postData.append('qualification', qualification)
    postData.append('youtube', youtube)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  video_data(title, description, link, user_id, post_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'articles');
    postData.append('title', title)
    postData.append('description', description)
    postData.append('link', link)
    postData.append('user_id', user_id)
    postData.append('post_type', post_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  update_video_data(id,title, description, link, user_id, post_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'update_data');
    postData.append('table_name', 'articles');
    postData.append('id', id)
    postData.append('title', title)
    postData.append('description', description)
    postData.append('link', link)
    // postData.append('user_id', user_id)
    postData.append('post_type', post_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  article_data(title, description, link, image, user_id, post_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'articles');
    postData.append('title', title)
    postData.append('description', description)
    postData.append('link', link)
    postData.append('image', image)
    postData.append('user_id', user_id)
    postData.append('post_type', post_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  update_article_data(id,title, description, link, image, user_id, post_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'update_data');
    postData.append('table_name', 'articles');
    postData.append('id', id)
    postData.append('title', title)
    postData.append('description', description)
    postData.append('link', link)
    postData.append('image', image)
    // postData.append('user_id', user_id)
    postData.append('post_type', post_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  event_data(title, description, link, image, user_id, post_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'articles');
    postData.append('title', title)
    postData.append('description', description)
    postData.append('link', link)
    postData.append('image', image)
    postData.append('user_id', user_id)
    postData.append('post_type', post_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  update_event_data(id,title, description, link, image, user_id, post_type): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'update_data');
    postData.append('table_name', 'articles');
    postData.append('id', id)
    postData.append('title', title)
    postData.append('description', description)
    postData.append('link', link)
    postData.append('image', image)
    // postData.append('user_id', user_id)
    postData.append('post_type', post_type)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  ask_question(user_id, ques,imam_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'questions');
    postData.append('user_id', user_id)
    postData.append('question', ques)
    postData.append('imam_id',imam_id)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  answer_ques(imam_id,status): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'questions');
    postData.append('imam_id',imam_id)
    postData.append('status', status)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  answer_ques1(user_id, status): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'questions');
    postData.append('user_id', user_id)
    postData.append('status', status)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  imam_answer(imam_id,status): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'questions');
    postData.append('imam_id', imam_id)
    postData.append('status', status)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  imam_answer1(imam_id,user_id,status): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'questions');
    postData.append('imam_id', imam_id)
    postData.append('user_id', user_id)
    postData.append('status', status)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  update_data(id, imam_answer, quran_proof, hadith_proof, imam_id, video_link,status): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'update_data');
    postData.append('table_name', 'questions');
    postData.append('id', id)
    postData.append('imam_answer', imam_answer)
    postData.append('quran_proof', quran_proof)
    postData.append('hadith_proof', hadith_proof)
    postData.append('imam_id', imam_id)
    postData.append('video_link', video_link)
    postData.append('status', status)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  posts(user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'articles');
    postData.append('user_id', user_id)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  imam_post(imam_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'articles');
    postData.append('imam_id', imam_id)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  like_dislike(user_id, to_id, like_type, status): Observable<[]> {
    let postData = new FormData();
    postData.append('type', 'like_dislike');
    postData.append('user_id', user_id);
    postData.append('to_id', to_id);
    postData.append('like_type', like_type);
    postData.append('status', status);
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  imam_lists(user_id,user_type): Observable<[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'users');
    postData.append('user_id', user_id)
    postData.append('user_type', user_type);
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  fvt_post(user_id): Observable<[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'favourites');
    postData.append('user_id', user_id);
    postData.append('like_type', 'questions');
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  fvt_imam(user_id): Observable<[]> {
    let postData = new FormData();
    postData.append('type', 'get_data');
    postData.append('table_name', 'followers');
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  volunteer_signup(user_id, first_name, last_name, email, address, phone, age, description): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'sponsors');
    postData.append('s_type', '1');
    postData.append('user_id', user_id)
    postData.append('first_name', first_name)
    postData.append('last_name', last_name)
    postData.append('email', email)
    postData.append('address', address)
    postData.append('phone', phone)
    postData.append('age', age)
    postData.append('description', description)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  sponser_signup(user_id, first_name, last_name, email, business, website, address, description, image): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'sponsors');
    postData.append('s_type', '0');
    postData.append('user_id', user_id)
    postData.append('first_name', first_name)
    postData.append('last_name', last_name)
    postData.append('email', email)
    postData.append('address', address)
    postData.append('business', business)
    postData.append('website', website)
    postData.append('description', description)
    postData.append('image', image)
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }
  follow_unfollow(user_id,imam_id): Observable<[]> {
    let postData = new FormData();
    postData.append('type', 'follow_unfollow');
    postData.append('user_id', user_id);
    postData.append('imam_id',imam_id);
    // postData.append('status',status);
    return this.http.post(this.link, postData).pipe(map(res => res.json()))
  }


  async toast(message) {

    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      cssClass: 'toast',
      animated: true,
      color: 'dark',
      mode: 'ios',
    });
    toast.present();
  }
  randomNumber(){
    var val = Math.floor(1000 + Math.random() * 9000);
    return val
  }
}
