import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ApiService } from './api/api.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  login_by:any
  user_id:any
  constructor(public router:Router,public service: ApiService) {
          // this.router.navigate(['home'])

    // let check = window.localStorage.getItem('intro_store')
    // this.login_by=this.service.getLoginOption();
    //   if (check == 'true') {
    //     if(this.login_by == 'imam')z
    //     {
    //       this.router.navigate(['imam-dashboard'])
    //     }
    //     else{
    //       this.router.navigate(['home'])
    //     }
    //   }
    //   else{
    //     this.router.navigate(['home'])
    //   }
    this.getProfile()
  }
  
  getProfile(){
    this.user_id = window.localStorage.getItem('user_id_ask')
    this.service.profile(this.user_id).subscribe(res=>{
      console.log(res);
      this.service.setProfile(res['profile'])
    })
  }
  ionViewWillEnter(){
    console.log("app compo");   
  }
}
