import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'my-popover',
    loadChildren: () => import('./my-popover/my-popover.module').then( m => m.MyPopoverPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'login-popover',
    loadChildren: () => import('./login-popover/login-popover.module').then( m => m.LoginPopoverPageModule)
  },
  {
    path: 'question-modal',
    loadChildren: () => import('./question-modal/question-modal.module').then( m => m.QuestionModalPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'user-dashboard',
    loadChildren: () => import('./user-dashboard/user-dashboard.module').then( m => m.UserDashboardPageModule)
  },
  {
    path: 'imam-dashboard',
    loadChildren: () => import('./imam-dashboard/imam-dashboard.module').then( m => m.ImamDashboardPageModule)
  },
  {
    path: 'country-list',
    loadChildren: () => import('./country-list/country-list.module').then( m => m.CountryListPageModule)
  },
  {
    path: 'imam-profile/:abc/:xyz',
    loadChildren: () => import('./imam-profile/imam-profile.module').then( m => m.ImamProfilePageModule)
  },
  {
    path: 'share-modal',
    loadChildren: () => import('./share-modal/share-modal.module').then( m => m.ShareModalPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'register-imam',
    loadChildren: () => import('./register-imam/register-imam.module').then( m => m.RegisterImamPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
